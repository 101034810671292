import React, { useState, useEffect, useRef } from 'react';  

/**
 * Icons
 */
import { MapPin, Star, Heart, ChevronLeft, ChevronRight } from 'lucide-react';

/**
 * Placeholder data
 */
/**
 * Placeholder data
 */
/**
 * Placeholder data
 */
const placeholder_data = [
  {
    id: 1,
    title: 'Gas-powered generator with 5000W capacity',
    rating: 5.0,
    location: 'Manhattan, NY (3 mi)',
    price: 50,
    priceUnit: 'Day',
    image: '/static/assets/img/generator.png',
  },
  {
    id: 2,
    title: 'Electric-powered generator with 5000W capacity',
    rating: 4.5,
    location: 'Brooklyn, NY (5 mi)',
    price: 60,
    priceUnit: 'Day',
    image: '/static/assets/img/generator.png',
  },
  {
    id: 3,
    title: 'Gas-powered generator with 7000W capacity',
    rating: 4.8,
    location: 'Queens, NY (10 mi)',
    price: 70,
    priceUnit: 'Day',
    image: '/static/assets/img/generator.png',
  },
  {
    id: 4,
    title: 'Gas-powered generator with 5000W capacity',
    rating: 4.7,
    location: 'Bronx, NY (15 mi)',
    price: 80,
    priceUnit: 'Day',
    image: '/static/assets/img/generator.png',
  },
  {
    id: 5,
    title: 'Gas-powered generator with 5000W capacity',
    rating: 4.9,
    location: 'Staten Island, NY (20 mi)',
    price: 90,
    priceUnit: 'Day',
    image: '/static/assets/img/generator.png',
  },
  {
    id: 6,
    title: 'Gas-powered generator with 5000W capacity',
    rating: 4.6,
    location: 'Newark, NJ (30 mi)',
    price: 100,
    priceUnit: 'Day',
    image: '/static/assets/img/generator.png',
  },
  {
    id: 7,
    title: 'Gas-powered generator with 5000W capacity',
    rating: 4.7,
    location: 'Jersey City, NJ (35 mi)',
    price: 110,
    priceUnit: 'Day',
    image: '/static/assets/img/generator.png',
  },
  {
    id: 8,
    title: 'Gas-powered generator with 5000W capacity',
    rating: 4.8,
    location: 'Hoboken, NJ (40 mi)',
    price: 120,
    priceUnit: 'Day',
    image: '/static/assets/img/generator.png',
  },
  {
    id: 9,
    title: 'Gas-powered generator with 5000W capacity',
    rating: 4.9,
    location: 'New Brunswick, NJ (45 mi)',
    price: 130,
    priceUnit: 'Day',
    image: '/static/assets/img/generator.png',
  },
  {
    id: 10,
    title: 'Gas-powered generator with 5000W capacity',
    rating: 4.5,
    location: 'Trenton, NJ (50 mi)',
    price: 140,
    priceUnit: 'Day',
    image: '/static/assets/img/generator.png',
  },
  {
    id: 11,
    title: 'Gas-powered generator with 5000W capacity',
    rating: 4.7,
    location: 'Philadelphia, PA (100 mi)',
    price: 150,
    priceUnit: 'Day',
    image: '/static/assets/img/generator.png',
  },
  {
    id: 12,
    title: 'Gas-powered generator with 5000W capacity',
    rating: 4.8,
    location: 'Harrisburg, PA (150 mi)',
    price: 160,
    priceUnit: 'Day',
    image: '/static/assets/img/generator.png',
  },
];

export const SingleCard = ({ single_listing }) => {
  return (
    <div className="bg-white rounded-xl overflow-hidden shadow-sm w-full">
      <div className="relative">
        <img
          src={single_listing.image}
          alt={single_listing.title}
          width={400}
          height={300}
          className="w-full object-cover"
        />
      </div>

      <div className="p-4 space-y-4">
        <div className="flex items-start justify-between">
          <span className="text-2xl font-medium text-gray-900 font-sans w-10/12">
            {single_listing.title}
          </span>
          <div className="flex items-center justify-end gap-1 text-amber-500 w-2/12">
            <Star className="h-5 w-5 fill-current" />
            <span className="font-semibold">{single_listing.rating}</span>
          </div>
        </div>

        <div className="flex items-center text-gray-600">
          <MapPin className="h-5 w-5 mr-2 fill-transparent" />
          <span>{single_listing.location}</span>
        </div>

        <div className="flex items-center justify-between pt-2">
          <div className="flex items-baseline">
            <span className="text-2xl font-bold text-emerald-400 font-sans">
              ${single_listing.price}
            </span>
            <span className="text-xl text-gray-600 ml-2">Day</span>
          </div>
          <button
            className="text-gray-400 hover:text-red-500 transition-colors"
            aria-label="Add to favorites"
          >
            <Heart className="h-6 w-6 fill-transparent" />
          </button>
        </div>
      </div>
    </div>
  );
};

const ItemsListing = ({
  list_items = placeholder_data,
  title = 'Placeholder',
  description = 'Descriptions goes here',
  button_text = 'View All',
}) => {
  const [Swiper, setSwiper] = useState(null); // State for dynamically imported Swiper
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  // Dynamically import Swiper
  useEffect(() => {
    const loadSwiper = async () => {
      const { Swiper, SwiperSlide } = await import('swiper/react');
      const { Navigation } = await import('swiper/modules'); // Dynamically import Navigation
      setSwiper({ Swiper, SwiperSlide, Navigation });
    };
    loadSwiper();
  }, []);

  if (!Swiper) {
    return <div className='container mx-auto h-20 border text-center'>
      Loading...
    </div>;
  }

  return (
    <div className="mx-auto marketplaceSectionWrapper pt-14">
      <div className="w-full">
        <div className="flex justify-between w-full items-center">
          <div className="max-w-3xl">
            <h1 className="text-[2.5rem] font-bold text-[#0a0a2e] mb-4">
              {title}
            </h1>
            <p className="text-lg leading-relaxed font-sans text-[#0a0a2e]/60">
              {description}
            </p>
          </div>
          <button className="h-16 px-6 rounded-full bg-[var(--marketplaceColor)] hover:bg-[#4bc9b4] text-white font-semibold text-lg transition-colors w-fit hidden md:flex items-center justify-center">
            {button_text}
          </button>
        </div>
      </div>
      <div className="relative">
        {/* Custom navigation buttons */}
        <button
          ref={prevRef}
          className="absolute left-0 z-10 flex items-center justify-center h-10 w-10 bg-white shadow-md rounded-full -translate-y-1/2 top-1/2 transform hover:text-gray-600 hover:bg-gray-100 -translate-x-1/2 disabled:hidden"
          aria-label="Previous Slide"
        >
          <ChevronLeft className="h-5 w-5 text-black fill-none" />
        </button>
        <button
          ref={nextRef}
          className="absolute right-0 z-10 flex items-center justify-center h-10 w-10 bg-white shadow-md rounded-full -translate-y-1/2 top-1/2 transform hover:text-gray-600 translate-x-1/2 disabled:hidden"
          aria-label="Next Slide"
        >
          <ChevronRight className="h-5 w-5 text-black fill-none" />
        </button>

        <Swiper.Swiper
          spaceBetween={20}
          slidesPerView={1}
          modules={[Swiper.Navigation]}
          onInit={swiper => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          breakpoints={{
            528: { slidesPerView: 1.7 },
            1024: { slidesPerView: 3 },
          }}
          className="swiper-wrapper py-5"
        >
          {list_items.map(single_listing => (
            <Swiper.SwiperSlide
              key={single_listing.id}
              className="swiper-slide"
            >
              <SingleCard single_listing={single_listing} />
            </Swiper.SwiperSlide>
          ))}
        </Swiper.Swiper>
      </div>
    </div>
  );
};

export default ItemsListing;
