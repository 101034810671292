import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration, {
  ACCOUNT_SETTINGS_PAGES,
} from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconAmenities,
  IconCollection,
} from '../../components';
import Modal from '../Modal/Modal';
import css from './TopbarDesktop.module.css';
import { getDoLater, getVerificationStatus } from '../../util/dataExtractor';
import { PENDING } from '../../util/enums';
import { createResourceLocatorString } from '../../util/routes';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentSearchParams,
    currentUserListing,
    currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    active,
    location,
    editListing,
    OwnListing,
    history,
  } = props;
  const currentPath = location?.pathname;
  const isEditListing = currentPath.includes('/l/draft/');
  const [openModal, setopenModal] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const uuid = currentUser && currentUser.id && currentUser.id.uuid;
  const handleModel = () => {
    setopenModal(true);
  };

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  // const search = (
  //   <TopbarSearchForm
  //     className={css.searchLink}
  //     desktopInputRoot={css.topbarSearchWithLeftPadding}
  //     onSubmit={onSearchSubmit}
  //     initialValues={initialSearchFormValues}
  //     currentSearchParams={currentSearchParams}
  //   />
  // );

  const notificationDot =
    notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  // const inboxLink = authenticatedOnClientSide ? (
  //   <NamedLink
  //     className={css.inboxLink}
  //     name="InboxPage"
  //     params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
  //   >
  //     <span className={css.inbox}>
  //       <FormattedMessage id="TopbarDesktop.inbox" />
  //       {notificationDot}
  //     </span>
  //   </NamedLink>
  // ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' &&
      ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage
      ? css.currentPage
      : null;
  };

  const status = getVerificationStatus(currentUser);
  const didDoLater = getDoLater(currentUser);

  const profileMenu = authenticatedOnClientSide ? (
    <div className={css.avatarMenu}>
      <Menu>
        <MenuLabel
          className={css.profileMenuLabel}
          isOpenClassName={css.profileMenuIsOpen}
        >
          <IconCollection name="HAMBURGER_MENU" />
        </MenuLabel>
        {/* Dropdown Nav Menu */}
        <MenuContent className={css.profileMenuContent}>
          {/* Rentals  */}
          <MenuItem key="RentalPage">
            <NamedLink
              className={classNames(
                css.messageLink,
                currentPageClass('RentalPage')
              )}
              name="RentalPage"
            >
              <div className={css.myListing}>
                <span className={css.menuItemBorder} />
                <IconCollection name="MY_LISTING" />
                <FormattedMessage id="TopbarDesktop.rentals" />
              </div>
            </NamedLink>
          </MenuItem>
          {/* Messages  */}

          <MenuItem key="MessagePage">
            <NamedLink
              className={classNames(
                css.messageLink,
                currentPageClass('MessageListPage')
              )}
              name="MessageListPage"
            >
              <IconCollection name="DROPDOWN_MESSAGE" />
              <FormattedMessage id="TopbarDesktop.messageList" />
            </NamedLink>
          </MenuItem>

          {/* Saved items  */}
          <MenuItem key="SavedListingPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('SavedListingPage')
              )}
              name="SavedListingPage"
            >
              <IconCollection name="DROPDOWN_SAVED_ITEMS" />
              <FormattedMessage id="TopbarDesktop.savedItems" />
            </NamedLink>
          </MenuItem>
          {/* Dashboard  */}
          <MenuItem key="DashboardPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('DashboardPage')
              )}
              name="DashboardPage"
            >
              <IconCollection name="DROPDOWN_DASHBOARD" />
              <FormattedMessage id="TopbarDesktop.dashboard" />
            </NamedLink>
          </MenuItem>
          {/* My Listings  */}

          <MenuItem key="ManageListingPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ManageListingPage')
              )}
              name="ManageListingPage"
            >
              <IconCollection name="DROPDOWN_MY_LISTING" />
              <FormattedMessage id="TopbarDesktop.myListings" />
            </NamedLink>
          </MenuItem>
          {/* <MenuItem key="EditListingPage">
            <OwnListingLink
              listing={currentUserListing}
              listingFetched={currentUserListingFetched}
              className={css.yourListingsLink}
            >
              <div className={css.myListing}>
                <span className={css.menuItemBorder} />
                <IconCollection name="DROPDOWN_MY_LISTING" />
                {currentUserListing ? (
                  <FormattedMessage id="TopbarDesktop.editYourListingLink" />
                ) : (
                  <FormattedMessage id="TopbarDesktop.myListings" />
                )}
              </div>
            </OwnListingLink>
          </MenuItem> */}

          {/* profile  */}
          <MenuItem key="ProfilePage">
            {uuid && (
              !!currentUser?.attributes?.profile?.publicData?.businessName ? (
                <NamedLink
                  className={classNames(
                    css.profileSettingsLink,
                    currentPageClass('ProfilePage')
                  )}
                  params={{ id: uuid }}
                  name="BusinessProfileSelfPage"
                >
                  <IconAmenities type="userProfile" />
                  <span>Business Profile</span>
                </NamedLink>
              ) : (
              <NamedLink
                className={classNames(
                  css.profileSettingsLink,
                  currentPageClass('ProfilePage')
                )}
                name="ProfilePage"
                params={{ id: uuid }}
              >
                <IconAmenities type="userProfile" />
                <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
              </NamedLink>)
            )}
          </MenuItem>
          <MenuItem key="PersonalInformationPage">
            {uuid && (
              <NamedLink
                className={classNames(
                  css.profileSettingsLink,
                  currentPageClass('PersonalInformationPage')
                )}
                name="ProfileSettingsPage"
                params={{ id: uuid }}
              >
                <p>Personal Information</p>
              </NamedLink>
            )}
          </MenuItem>
          <MenuItem key="PaymentMethodsPage">
            {uuid && (
              <NamedLink
                className={classNames(
                  css.profileSettingsLink,
                  currentPageClass('PaymentMethodsPage')
                )}
                name="PaymentMethodsPage"
                params={{ tab: 'payment-methods' }}
              >
                <p>Payments & payouts</p>
              </NamedLink>
            )}
          </MenuItem>
          <MenuItem key="AddressPage">
            {uuid && (
              <NamedLink
                className={classNames(
                  css.profileSettingsLink,
                  currentPageClass('AddressPage')
                )}
                name="AddressPage"
                params={{ id: uuid }}
              >
                <p>Address</p>
              </NamedLink>
            )}
          </MenuItem>
          <MenuItem key="HelpCenter">
            <p className={classNames(
              css.profileSettingsLink,
              currentPageClass('AddressPage')
            )}
              onClick={() => window.window.Tawk_API.maximize()}>Help Center</p>
          </MenuItem>
          {/* logout  */}
          <MenuItem key="logout">
            <InlineTextButton
              rootClassName={css.logoutButton}
              onClick={onLogout}
            >
              <IconAmenities type="logout" />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
      <Avatar className={css.avatar} user={currentUser} disableProfileLink />
    </div>
  ) : null;

  const homeLink = isAuthenticatedOrJustHydrated ? (
    <NamedLink
      name="LandingPage"
      className={classNames(
        css.signupLink,
        active == 'landing' && css.activeLink
      )}
    >
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.homeLink" />
      </span>
    </NamedLink>
  ) : (
    <NamedLink
      name="LandingPage"
      className={classNames(
        css.signupLink,
        active == 'landing' && css.activeLink
      )}
    >
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.homeLink" />
      </span>
    </NamedLink>
  );
  const howItWorks = isAuthenticatedOrJustHydrated ? (
    // <NamedLink name="SearchPage" className={classNames(css.signupLink, active == "howItWorks" && css.activeLink)}>
    //   <span className={css.signup}>
    //     <FormattedMessage id="TopbarDesktop.howItWorks" />
    //   </span>
    // </NamedLink>
    <HashLink
      className={classNames(
        css.signupLink,
        active == 'howItWorks' && css.activeLink
      )}
      to="/#how-it-works"
    >
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.howItWorks" />{' '}
      </span>
    </HashLink>
  ) : (
    // <NamedLink
    //   name="SearchPage"
    //   className={classNames(
    //     css.signupLink,
    //     active == 'howItWorks' && css.activeLink
    //   )}
    // >
    //   <span className={css.signup}>
    //     <FormattedMessage id="TopbarDesktop.howItWorks" />
    //   </span>
    // </NamedLink>
    <HashLink
      className={classNames(
        css.signupLink,
        active == 'howItWorks' && css.activeLink
      )}
      to="/#how-it-works"
    >
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.howItWorks" />{' '}
      </span>
    </HashLink>
  );

  // const FAQ = isAuthenticatedOrJustHydrated ? (
  //   <NamedLink name="AboutPage" className={classNames(css.signupLink, active == "faq" && css.activeLink)}>
  //     <span className={css.signup}>
  //       <FormattedMessage id="TopbarDesktop.FAQ" />
  //     </span>
  //   </NamedLink>
  // ) : (
  //   <NamedLink name="AboutPage" className={classNames(css.signupLink, active == "faq" && css.activeLink)}>
  //     <span className={css.signup}>
  //       <FormattedMessage id="TopbarDesktop.FAQ" />
  //     </span>

  //   </NamedLink>
  // );

  const support =
    <a className={css.signupLink} onClick={() => window.window.Tawk_API.maximize()}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.helpCenter" />
      </span>
    </a>;

  const doLater = isAuthenticatedOrJustHydrated ? (
    <NamedLink name="IdentityPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.identityPageLink" />
      </span>
    </NamedLink>
  ) : null;

  const listAnItem = (
    <div
      className={`${css.signupLink} ${css.listItem}`}
      onClick={() => {
        if (OwnListing && OwnListing.length < 51) {
          history.push(
            createResourceLocatorString(
              'NewListingPage',
              routeConfiguration(),
              {},
              {}
            )
          );
        } else {
          handleModel();
        }
      }}
    >
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.listAnItem" />
      </span>
    </div>
  );

  const myListing = (
    <NamedLink name="M<a" className={`${css.signupLink} ${css.listItem}`}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.lisAnItem" />
      </span>
    </NamedLink>
  );

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="SignupPage"
      className={classNames(
        css.signupLink,
        active == 'signUp' && css.activeLink
      )}
      style={{ textDecoration: 'underline' }}
    >
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="LoginPage"
      className={classNames(css.loginLink, active == 'logIn' && css.activeLink)}
    >
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  // const listingLink =
  //   authenticatedOnClientSide &&
  //     currentUserListingFetched &&
  //     currentUserListing ? (
  //     <ListingLink
  //       className={css.createListingLink}
  //       listing={currentUserListing}
  //       children={
  //         <span className={css.createListing}>
  //           <FormattedMessage id="TopbarDesktop.viewListing" />
  //         </span>
  //       }
  //     />
  //   ) : null;

  // const createListingLink =
  //   isAuthenticatedOrJustHydrated && !(currentUserListingFetched && !currentUserListing) ? null : (
  //     <NamedLink className={css.createListingLink} name="NewListingPage">
  //       <span className={css.createListing}>
  //         <FormattedMessage id="TopbarDesktop.createListing" />
  //       </span>
  //     </NamedLink>
  //   );

  return (
    <nav className={classNames(classes, editListing && css.editListing)}>
      <div className={css.navMain}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <Logo
            format="desktop"
            className={css.logo}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
        </NamedLink>
        {/* {search} */}

        <div className={css.menuContainer}>
          <div className={css.menuList}>
            {homeLink}
            {howItWorks}
            {/* {FAQ} */}
            {support}
            {(status == PENDING || didDoLater) && doLater}
          </div>
          <div
            className={classNames(
              css.lastMenuList,
              !isAuthenticatedOrJustHydrated && css.lastMenuListBeforeLogin
            )}
          >
            {/* {listingLink} */}
            {/* {createListingLink} */}
            {/* {inboxLink} */}
            {isEditListing ? null : listAnItem}
            {profileMenu}
            {signupLink}
            {loginLink}
          </div>
        </div>
      </div>
      <Modal
        id={'TopbarDesktop.LimitListingMessage'}
        isOpen={openModal}
        onClose={() => {
          setopenModal(false);
        }}
        onManageDisableScrolling={() => { }}
      >
        <h5 className={css.ListingMessages}>
          <FormattedMessage id="LimitListingMessage" />{' '}
        </h5>
        <button className={css.buttonYes} onClick={() => setopenModal(false)}>
          OK
        </button>
        <button className={css.buttonNo} onClick={() => setopenModal(false)}>
          CANCEL
        </button>
      </Modal>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
