import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  Button,
  IconAmenities,
  IconCollection,
} from '../../components';
import css from './IdentityForm.module.css';
// import Persona from 'persona';
import { getVerificationStatus } from '../../util/dataExtractor';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';

const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

class IdentityFormComponent extends Component {
  constructor(props) {
    super(props);
    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false, persona: {} };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }
  componentDidMount() {
    if (window) {
      this.initPersona();
    }
  }

  initPersona = async () => {
    const personaSdk = await import('persona');
    personaSdk && this.setState({ persona: personaSdk });
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            rootClassName,
            updateProfileError,
            values,
            onHandleDoLater,
            onUpdateProfile,
            didDoLater,
          } = fieldRenderProps;

          const personaFunc = () => {
            const continueButton = document.getElementById('continueButton');
            continueButton.innerHTML = 'LOADING...';
            continueButton.disabled = true;

            const inquiryId =
              currentUser?.attributes?.profile?.protectedData?.inquiry_Id;
            const status = getVerificationStatus(currentUser);
            const templateId = 'itmpl_cwxoAS3K4udMjmxJmtbG86Li6bk3';
            const id = status === 'pending' ? inquiryId : templateId;

            const client = new this.state.persona.Client({
              templateId: process.env.REACT_APP_PERSONA_TEMPLATE_ID,
              environmentId: process.env.REACT_APP_ENVIRONMENT_Id,
              environment: 'sandbox',
              onReady: () => {
                client.open();
                continueButton.innerHTML = 'CONTINUE';
                continueButton.disabled = false;
              },
              onComplete: ({ inquiryId, status, fields }) => {
                const identity = {
                  inquiry_Id: inquiryId,
                  verification_status: status,
                  verification_fields: [fields],
                };
                // Inquiry completed. Optionally tell your server about it.
                if (identity?.verification_status == 'completed') {
                  client.cancel();
                  onUpdateProfile({
                    protectedData: identity,
                    publicData: {
                      doLater: false,
                    },
                  }).then(() => {
                    const authInfo = Cookies.get('st-authinfo') ? JSON.parse(Cookies.get('st-authinfo')) : null;
                    var redirect = authInfo ? authInfo.from : null;
                    if (redirect) {
                      this.props.history.push(redirect);
                      return;
                    }
                    const { history, location } = this.props;
                    const search = location.search;
                    const params = new URLSearchParams(search);
                    redirect = params.get('redirect');
                    if (redirect) {
                      history.push(redirect);
                      return;
                    }
                    return (
                      typeof widow !== undefined &&
                      window.open('/', '_self')
                    );
                  });
                }
              },
            });
          };


          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="IdentityForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);


          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
                <div
                  onClick={e => {
                    personaFunc();
                  }}
                >
                  <p className={css.uploadText}>
                    <FormattedMessage id="IdentityPage.uploadYourId" />
                  </p>

                </div>
                {submitError}
                <div className={css.thisInformation}>
                  <div>
                    <IconCollection name="IDENTIFY_VERIFICATION" />
                  </div>
                  <FormattedMessage id="IdentityForm.thisInformationWillNever" />
                </div>

                <div className={css.paragraphContent}>
                  <IconAmenities type="verified" />
                  <div className={css.paragraph}>
                    <h2>
                      <FormattedMessage id="IdentityForm.whyBecomeVerified" />
                    </h2>
                    <p>
                      {' '}
                      <FormattedMessage id="IdentityForm.atRuntizeSecurity" />
                    </p>
                  </div>
                  <div className={css.paragraph}>
                    <h2>
                      <FormattedMessage id="IdentityForm.canIskipThis" />
                    </h2>
                    <p>
                      <FormattedMessage id="IdentityForm.technicallyYesHowever" />
                    </p>
                  </div>
                </div>
                <div className={css.buttonContent}>
                  {!didDoLater && (
                    <Button type="button" onClick={onHandleDoLater}>
                      <FormattedMessage id="IdentityForm.doThisLater" />
                    </Button>
                  )}


                  <Button
                    id="continueButton"
                    className={css.submitButton}
                    type="button"
                    onClick={e => {
                      personaFunc();
                      e.target.innerHTML = '<div class="loader"></div>';
                      e.target.disabled = true;
                    }}
                  >
                    CONTINUE
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

IdentityFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
  maxNumberOfFiles: 0,
};

IdentityFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const IdentityForm = compose(withRouter, injectIntl)(IdentityFormComponent);

IdentityForm.displayName = 'IdentityForm';

export default IdentityForm;
