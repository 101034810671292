import {
  AvatarLarge,
  Footer,
  IconAmenities,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  ManageListingCard,
  NamedLink,
  Page,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './BusinessProfileSelf.module.css';

import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { draftId, draftSlug } from '../../routeConfiguration';
import { closeListing, getOwnListingsById, openListing } from '../ManageListingsPage/ManageListingsPage.duck';
import { useEffect, useMemo, useState } from 'react';
import Dropdown from 'react-dropdown';
import { displayCategories } from '../../marketplace-custom-config';
import "../BusinessProfileOthers/Dropdown.style.css";
import { queryUserReviews } from '../ProfilePage/ProfilePage.duck';
import { calculateAverageUserReview, totalUserReviewCount } from '../../util/data';
import { REVIEW_TYPE_OF_PROVIDER } from '../../util/types';
import ProfileReviews from '../ProfilePage/ProfileReviews';
import { SingleCard } from '../../components/customComponents/items_listing';

export const ensureURL = (url) => {
  if (!url.includes('http')) {
    return `https://${url}`;
  }
  return url;
}

const BusinessProfileSelfComponent = (props) => {
  const {
    currentUser,
    listings,
    onCloseListing,
    onOpenListing,
    reviews,
    fetchUserReviews,
    location,
  } = props;
  const publicData = currentUser?.attributes?.profile?.publicData;
  const [listingMenuOpen, setListingMenuOpen] = useState(null);
  const filterOptions = useMemo(() => {
    if (!listings) {
      return null;
    }
    const categoryToNumberOfListings = listings.reduce((acc, listing) => {
      const category = listing.attributes.publicData.category;
      return {
        ...acc,
        [category]: acc[category] ? acc[category] + 1 : 1,
      };
    }, {});
    return [
      { label: `All Product: (${listings?.length || 0})`, value: '' },
      ...Object.keys(categoryToNumberOfListings).map(category => ({
        label: `${displayCategories.find(c => c.key.toLowerCase() === category.toLowerCase())?.value} (${categoryToNumberOfListings[category]})`,
        value: category,
      }))
    ];
  }, [listings]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const reviewsOfProvider = reviews.filter(
    r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER
  );
  const averageProviderReview = calculateAverageUserReview(reviewsOfProvider, []);
  const reviewCountProvider = totalUserReviewCount(reviewsOfProvider.length, []);
  const [showReviews, setShowReviews] = useState(false);
  const { hash } = location;

  useEffect(() => {
    if (currentUser?.id) {
      fetchUserReviews(currentUser.id);
    }
  }, [currentUser?.id]);

  useEffect(() => {
    if (hash === '#reviews') {
      setShowReviews(true);
    }
    else {
      setShowReviews(false);
    }
  }, [hash]);

  if (!listings || !filterOptions) {
    return null;
  }

  return (
    <div className='container mx-auto flex flex-col'>
      <div className={`${css.profileHeader}`}>
        <div>
          <AvatarLarge
            className={`${css.profilePic}`}
            user={currentUser}
          />
        </div>
        <div>
          <h3 className={css.profileTitle}>{publicData?.businessName}</h3>
          <div className={css.ratingBox}>
            <IconAmenities type="star" />
            <span>{averageProviderReview} (<a href='#reviews' style={{ color: '#000', textDecoration: 'underline' }}>{reviewCountProvider}</a>)</span>
          </div>
          <div className={css.verifiedBox}>
            <span className={css.verifiedIcon}>
              <IconAmenities type="verified_icon" />
              <span>Verified Business</span>
            </span>
            <NamedLink
              name='CreateYourBusinessProfilePage'
            >
              <IconAmenities type="edit" />
            </NamedLink>
          </div>
          <div className={css.social_icons}>
            {!!publicData?.website && <a href={ensureURL(publicData?.website)} target="_blank">
              <IconAmenities type="website_gray" />
            </a>}
            {!!publicData?.zipLocation && <a href={`https://www.google.com/maps/search/?api=1&query=${publicData?.zipLocation}`} target="_blank">
              <IconAmenities type="location_gray" />
            </a>}

            {publicData?.facebook && <a href={ensureURL(publicData?.facebook)} target="_blank">
              <IconAmenities type="facebook_gray" />
            </a>}

            {!!publicData?.instagram && <a href={ensureURL(publicData?.instagram)} target="_blank">
              <IconAmenities type="instagram_gray" />
            </a>}

            {!!publicData?.linkedIn && <a href={ensureURL(publicData?.linkedIn)} target="_blank">
              <IconAmenities type="linkedin_gray" />
            </a>}

            {!!publicData?.twitter && <a href={ensureURL(publicData?.twitter)} target="_blank">
              <IconAmenities type="twitter_gray" />
            </a>}
          </div>
        </div>
      </div>

      {showReviews}
      {/* Filter */}
      {/* {!!listings && !showReviews && <div>
        <button class={css.filter_box}>
          <Dropdown
            className={css.filter_dropdown}
            options={filterOptions}
            onChange={(option) => setSelectedCategory(option.value)}
            value={selectedCategory}
            placeholder="Select an option"
            arrowOpen={<IconAmenities type="arrow_up" />}
            arrowClosed={<IconAmenities type="arrow_down" />}
            controlClassName={css.Dropdown_control}
            menuClassName={css.Dropdown_menu}
          />
        </button>
      </div>} */}

      {/* Products */}
      {/* {!showReviews && <div className={css.products_box}>
        {!!listings && listings
          .filter(listing => selectedCategory === '' || listing.attributes.publicData.category === selectedCategory)
          .map((listing) => (
            <ManageListingCard
              className={css.listingCard}
              key={listing.id.uuid}
              listing={listing}
              isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === listing.id.uuid}
              actionsInProgressListingId={false}
              onToggleMenu={setListingMenuOpen}
              onCloseListing={onCloseListing}
              onOpenListing={onOpenListing}
            />
          ))}
      </div>} */}

      {/* {!!showReviews && <ProfileReviews reviews={reviews} user={currentUser} isCurrentUser={true} />} */}

      {/* Plus Icon */}
      {/* <div className={css.plus}>
        <NamedLink
          name="EditListingPage"
          params={{
            slug: draftSlug,
            id: draftId,
            type: 'new',
            tab: 'description',
          }}
        >
          <IconAmenities type="plus" />
        </NamedLink>
      </div> */}

      <div className='grid md:grid-cols-4 grid-cols-1 gap-2 my-5'> 
        {Array.from({ length: 8 }).map((_, i) => (
          <SingleCard key={i} />
        ))}
      </div>
    </div>
  );
};

const BusinessProfileSelfPage = (props) => {
  return (
    <Page>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.root}>
            <BusinessProfileSelfComponent {...props} />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const { currentPageResultIds, queryParams, queryInProgress } = state.ManageListingsPage;
  const { reviews } = state.ProfilePage
  const listings = getOwnListingsById(state, currentPageResultIds);
  return {
    listings: !!queryParams && !queryInProgress ? listings : null,
    currentUser,
    reviews
  };
};

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
  fetchUserReviews: userId => dispatch(queryUserReviews(userId))
});

const BusinessProfileSelf = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(BusinessProfileSelfPage);

export default BusinessProfileSelf;
